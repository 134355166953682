import React from 'react';
import './about.css'; 

export default function About() {
  return (
    <>
          <div className="about-boxtwo">
            <h1>
    Welcome To  <br/> &nbsp; &nbsp; &nbsp; <span className='PurpCode'>Code</span><span className='WhiteCode'>Place</span>,
</h1>

          </div>
          <div className="about-container">
          <div className="about-boxthree">
             Learn about CodePlace For Developers
          </div>
          <div className="about-boxfour">
           Learn about CodePlace for clients
          </div>
          </div>


         <div className="Containertwo-about">
             <h1>
            What is <span className='PurpCode'>Code</span><span className='WhiteCode'>Place</span>
             </h1>
<p> CodePlace is a Freelance Software marketplace that connects   </p>
<h1>Why use <span className='PurpCode'>Code</span><span className='WhiteCode'>Place</span>?</h1>
<p></p>

         </div>
          
          
          
          
          
          
          </>
  );
}
